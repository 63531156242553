<template>
  <b-sidebar
    id="sidebarFilter"
    v-model="isShowSidebar"
    title="FILTER"
    aria-labelledby="sidebar-no-header-title"
    no-header
    backdrop
    shadow
    right
    ref="sidebarFilter"
    width="80vw"
  >
    <div class="px-3">
      <b-row class="header-filter">
        <b-col class="text-filter"> Filter </b-col>
        <b-col class="text-right">
          <button class="btn-clear" @click.prevent="clearFilter">
            x <span class="text-under">Clear Filter</span>
          </button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
          <b-input-group>
            <b-form-input
              type="search"
              class="search-bar"
              @keyup.enter="searchCouponList"
              placeholder="Search Coupon Name"
              v-model="filter.search"
            >
            </b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-3">
          <label>Valid Form: </label>
          <div class="input-container">
            <datetime
              type="datetime"
              placeholder="Please Select Date"
              format="dd/MM/yyyy HH:mm:ss"
              value-zone="local"
              v-model="validFrom"
              ref="startDate"
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.startDate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer color-primary"
                color="#B41BB4"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-3">
          <label>Valid To: </label>
          <div class="input-container">
            <datetime
              type="datetime"
              placeholder="Please Select Date"
              format="dd/MM/yyyy HH:mm:ss"
              value-zone="local"
              v-model="validTo"
              ref="enddate"
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.enddate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer color-primary"
                color="#B41BB4"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-3">
          <b-form-group>
            <template #label>
              <b>Status: </b><br />
              <b-form-checkbox
                v-model="allSelected"
                aria-describedby="status"
                aria-controls="status"
                @change="toggleAll"
              >
                Select All
              </b-form-checkbox>
            </template>
            <template v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="status"
                v-model="selected"
                :options="status"
                :aria-describedby="ariaDescribedby"
                name="status"
                class="ml-4"
                aria-label="Individual status"
                stacked
              ></b-form-checkbox-group>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-3">
          <b-form-group>
            <template #label>
              <b>Coupon Type: </b><br />
              <b-form-checkbox
                v-model="allCouponSelected"
                aria-describedby="Redeem_Type"
                aria-controls="Redeem_Type"
                @change="toggleCouponAll"
              >
                Select All
              </b-form-checkbox>
            </template>
            <template v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                id="Redeem Type"
                v-model="filter.redeem_type"
                :options="redeemList"
                :aria-describedby="ariaDescribedby"
                name="Redeem_Type"
                class="ml-4"
                aria-label="Individual Redeem Type"
                stacked
              ></b-form-checkbox-group>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <template #footer>
      <div class="d-flex justify-content-between shadow align-items-center">
        <div class="w-100">
          <b-button
            block
            variant="dark"
            class="text-light rounded-pill"
            @click.prevent="hide"
          >
            Cancel
          </b-button>
        </div>
        <div class="w-100">
          <b-button
            @click.prevent="submitAction"
            block
            variant="light"
            class="text-body rounded-pill btn-main"
          >
            Search
          </b-button>
        </div>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
  },
  watch: {
    validFrom(val) {
      if (val) this.filter.start_date = val;
    },
    validTo(val) {
      if (val) this.filter.end_date = val;
    },
    selected(newValue) {
      if (newValue.length === 0) {
        this.allSelected = false;
      } else if (newValue.length === this.status.length) {
        this.allSelected = true;
      } else {
        this.allSelected = false;
      }
    },
    "filter.redeem_type"(newValue) {
      if (newValue.length === 0) {
        this.allCouponSelected = false;
      } else if (newValue.length === this.redeemList.length) {
        this.allCouponSelected = true;
      } else {
        this.allCouponSelected = false;
      }
    },
  },
  data() {
    return {
      isShowSidebar: false,
      validFrom: "",
      validTo: "",
      allSelected: false,
      allCouponSelected: false,
      selected: [],

      status: ["Active", "Inactive"],
      redeemList: [
        { text: "By Customer", value: 1 },
        { text: "By Booth", value: 0 },
      ],
    };
  },
  methods: {
    show() {
      this.isShowSidebar = true;
    },
    hide() {
      this.isShowSidebar = false;
    },
    searchCouponList() {},
    toggleAll(checked) {
      this.selected = checked ? this.status.slice() : [];
    },
    toggleCouponAll(checked) {
      this.filter.redeem_type = checked
        ? this.redeemList.map((el) => el.value)
        : [];
    },
    submitAction() {
      if (this.allSelected) {
        this.filter.status = null;
      } else {
        if (this.selected.length > 0) {
          let findSelectedActive = this.selected.includes("Active");
          if (findSelectedActive) {
            this.filter.status = 1;
          } else {
            this.filter.status = 0;
          }
        }
      }

      this.$emit("filterSearch", this.filter);
      this.hide();
    },
    clearFilter() {
      this.filter.status = null;
      this.allSelected = false;
      this.filter.start_date = "0001-01-01";
      this.filter.end_date = "0001-01-01";
      this.filter.search = "";
      this.validFrom = "";
      this.validTo = "";
      this.selected = [];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-container input {
  border: none;
}
.text-under {
  text-decoration: underline;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
::v-deep .btn-filter button,
.btn-filter {
  color: var(--primary-color) !important;
  border-color: var(--primary-color);
}
.header-filter {
  background-color: var(--secondary-color);
  //   color: #fff;
  padding: 15px 0;
}
.btn-close {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 18px;
}
.text-filter {
  font-weight: 600;
}
::v-deep .date-picker .vdatetime-input {
  width: 100%;
  color: var(--primary-color);
  border: 1px solid #d8dbe0;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 0.5rem;
}
label {
  margin-bottom: 0.5rem;
  font-weight: 600;
}
::v-deep .b-sidebar > .b-sidebar-body {
  overflow-x: hidden;
}
::v-deep .b-sidebar > .b-sidebar-header {
  font-size: 16px;
  display: block;
  padding: 0 1rem;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
</style>
